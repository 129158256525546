<template>
  <!--  爱心优惠券-->
  <el-card class="container">
    <div slot="header">
      <h3>爱心优惠券</h3>
    </div>

    <div class="body">
      <el-row :gutter="10">
        <el-col class="coupon" :span="8" v-for="i in list" :key="i">
          <el-card class="item">
            <el-row>
              <el-col :span="8" style="border-right: 1px solid #cccccc;">
                <h3>{{i.price}}元</h3>
                <p>{{i.title}}</p>
              </el-col>
              <el-col :span="12">
                <h3 class="text-left">{{i.name}}</h3>
                <p>有效期至{{i.end_time}}</p>
              </el-col>
              <el-col :span="4">
                <p>
                  <el-image :src="i.pic" fit="fill"></el-image>
                </p>
                <p>
                  <el-button type="text">删除</el-button>
                </p>
              </el-col>
            </el-row>


          </el-card>

        </el-col>
      </el-row>
    </div>

  </el-card>
</template>

<script>
  import axios from "axios"; 
  export default {
    name: "CouponIndex",
    data() {
      return {
		list:[],
        url: require("../../../assets/images/poster.png")
      }
    },
	created(){
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem("uid")); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getCoupon',params)
		.then((response) => {  
			console.log(response.data.data);
			this.list = response.data.data; 
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		   
	},
  }
</script>

<style scoped lang="scss">
  .el-card.container {
    min-height: 1050px;
    width: 99%;

    .el-card__header {
      h3 {
        padding: 0;
        margin: 0;
      }
    }

    .coupon {
      text-align: center;
      margin-bottom: 10px;

      .item {
        font-size: 12px;

        /deep/ .el-card__body {
          padding: 15px 5px;

          h3.text-left {
            padding-left: 12px;
          }

          .el-image {
            width: 36px;
            height: 36px;
            margin-bottom: 3px;
          }
          .el-button--text{
            padding: 0px;
            color: $green;
          }
        }

        h3 {
          font-size: 16px;
          margin: 0px;
          padding: 5px 0px 15px;
        }

        p {
          color: #999999;
        }
      }
    }
  }
</style>
